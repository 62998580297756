import { Elm } from "./Main.elm";

import * as signalR from "@microsoft/signalr";

const serverUrl = process.env.ELM_APP_SERVER_URL;
console.log("SERVER_URL: ", serverUrl);

var groupId = window.location.pathname.substring(1);
console.log(groupId);

var connection = new signalR.HubConnectionBuilder()
  .withUrl(`${serverUrl}/gameHub`)
  .build();

connection
  .start()
  .then(() => connection.invoke("SubscribeToGame", groupId))
  .catch(function (err) {
    console.log("Error establishing connection");
    console.error(err.toString());
  });

const app = Elm.Main.init({
  node: document.getElementById("root"),
});

connection.on("PlayerMoved", function ({ number, positionX, positionY }) {
  console.log("PlayerMoved: ", number, positionX, positionY);
  app.ports.setCoords.send({ x: positionX, y: positionY });
});

app.ports.sendMessage.subscribe(function (playerCoords) {
  let { gameId, playerNumber, newX, newY } = playerCoords;

  console.log("Calling 'SetPlayerCoords' for gameId: ", gameId);
  connection
    .invoke(
      "SetPlayerCoords",
      groupId /* TODO: gameId */,
      "Away",
      playerNumber,
      newX,
      newY
    )
    .catch(function (err) {
      console.log("Failed to invoke 'SetPlayerCoords': ", err);
    });
});
